<template>
  <div class="relative">
    <div class="view-header pt-1">
      <h2 class="heading-medium">Users</h2>

      <div
        v-if="allDataLoaded"
        class="flex flex-column mt-2">
        <f-button
          class="f-btn-add ml-auto no-shrink mb-3 mt-2"
          theme="light"
          @click.prevent="$router.push('/users/new')">
          Add User
        </f-button>

        <validation-observer
          ref="users-filters-form"
          tag="form"
          class="search-form flex flex-align-items-center">
          <div class="flex flex-column full-width mr-2">
            <f-input-wrapper
              prependIcon="search"
              class="full-width">
              <input
                type="search"
                v-model="filters.search"
                placeholder="Search by Name, Last Name or Email"/>
            </f-input-wrapper>
            <div class="form-group flex flex-wrap full-width mb-0">
              <f-input-wrapper
                v-for="(userType, userTypeIndex) in filters.userTypes"
                :key="'type-' + userTypeIndex"
                :inputType="'checkbox'"
                class="mt-1 mr-2">

                <input
                  type="checkbox"
                  class="small"
                  :id="'type-' + userType.id + '-' + userTypeIndex"
                  :name="'type-' + userType.id + '-' + userTypeIndex"
                  v-model="userType.selected" />
                <label :for="'type-' + userType.id + '-' + userTypeIndex">{{ userType.label }}</label>
              </f-input-wrapper>
            </div>
          </div>

          <div class="search-form-buttons flex ml-auto">
            <f-button
              theme="primary"
              :big="true"
              class="m2-1 mb-1-ms ml-0-sm text-nowrap"
              @click.prevent="validateFiltersForm">
              Search
            </f-button>

            <f-button
              theme="warning"
              :big="true"
              class="ml-1 text-nowrap"
              :disabled="resetFiltersDisabled"
              @click.prevent="clearFilters">
              Reset filters
            </f-button>
          </div>
        </validation-observer>
      </div>
    </div>

    <f-list
      ref="users-list"
      class="users-list"
      :loaded="allDataLoaded"
      :listSettings="listSettings"
      :listItems="usersFormatted" />

    <pagination
      :class="{'is-hidden': !allDataLoaded}"
      :meta-data="paginationMetaData" />

    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>


<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import Pagination from '@/components/core/Pagination.vue';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';

export default {
  name: 'users',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.filterUserTypesLoaded;
    },
    usersFormatted () {
      if (!this.users.length) {
        return [];
      }

      return this.users.map(item => {
        if (item.membership_package) {
          item.valid_to = this.formatDate(item.membership_package.valid_to);
        } else {
          item.valid_to = '-';
        }

        if (item.active) {
          item.status = { text: 'Active', additionalClass: 'text-success' };
        } else {
          item.status = { text: 'Inactive', additionalClass: 'text-danger' };
        }

        return item;
      });
    },
    resetFiltersDisabled () {
      let isDisabled = this.filters.search === '';
      if (this.filters.userTypes.find(item => item.selected)) {
        isDisabled = false;
      }
      return isDisabled;
    }
  },
  data () {
    return {
      users: [],
      loaded: false,
      loadError: false,
      removeInProgress: false,
      filters: {
        search: '',
        userTypes: []
      },
      order_by: 'last_name',
      order_direction: 'ASC',
      filterUserTypes: [],
      filterUserTypesLoaded: false,
      listSettings: [
        {
          title: 'First Name',
          variableName: ['first_name'],
          type: 'String'
        },
        {
          title: 'Last Name',
          variableName: ['last_name'],
          type: 'String'
        },
        {
          title: 'Email',
          variableName: ['email'],
          type: 'String',
          additionalClass: 'break-word'
        },
        {
          title: 'Status',
          variableName: ['status'],
          type: 'Object'
        },
        {
          title: 'Membership End Date',
          variableName: ['valid_to'],
          type: 'String'
        },
        {
          title: '',
          variableName: ['actonsList'],
          type: 'actions',
          items: [
            {
              disabled: false,
              callback: this.editUser,
              label: 'Edit',
              name: 'edit',
              theme: 'outline'
            },
            {
              disabled: false,
              enabledWhen: this.checkIsDeleteEnabled,
              callback: this.deleteUser,
              label: 'Delete',
              name: 'delete',
              theme: 'outline-danger'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    this.loadFilteredData();
    this.loadAdditionalData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let requestParams = {
        is_pagination: 1,
        order_by: this.order_by,
        order_direction: this.order_direction,
        page: this.currentPage
      };

      if (this.filters.search && this.filters.search.length) {
        requestParams.search = this.filters.search;
      }
      if (this.filters.userTypes) {
        requestParams.user_types = this.filters.userTypes.filter(item => item.selected).map(item => item.id);
      }

      ListUtils.loadItemsData(this, {
        endpoint: '/api/user/get-all',
        method: 'get',
        listField: 'users',
        params: requestParams
      });
    },
    editUser (item) {
      if (item.id) {
        this.$router.push('/users/edit/' + item.id);
      }
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['users-filters-form'], this.debouncedLoadFilteredData);
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        search: ''
      };

      this.filters.userTypes = this.filterUserTypes.map(item => {
        item.selected = false;
        return item;
      })

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    formatDate (date) {
      if (!date) {
        return 'No information';
      }

      let dateObject = new Date(date);

      return [
        dateObject.getDate(),
        dateObject.getMonth() + 1,
        dateObject.getFullYear()
      ].map(n => n < 10 ? '0' + n : n).join('/');
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/user/filter-user-types',
        method: 'get',
        outputKey: 'filterUserTypes',
        loadedKey: 'filterUserTypesLoaded',
        errorKey: 'loadError',
        noPagination: false,
        successAction: () => {
          Vue.nextTick(() => {
            if (this.filters.userTypes.length) {
              return;
            }

            for (let i = 0; i < this.filterUserTypes.length; i++) {
              let newType = {
                id: this.filterUserTypes[i].id,
                label: this.filterUserTypes[i].label,
                selected: false,
              }
              this.filters.userTypes.push(newType);
            }
          });
        }
      });
    },
    deleteUser (item) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/user/delete/',
        id: item.id,
        successTitle: 'User deleted',
        successText: 'Selected user\'s account has been deleted',
        confirmTitle: 'Are You sure You want to delete this user?',
        confirmText: 'This action cannot be undone',
        errorTitle: 'Error occured',
        errorText: 'User deletion failed. Try again.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        }
      });
    },
    checkIsDeleteEnabled (item) {
      if (item.is_admin) {
        return false;
      }
      if (item.has_active_membership) {
        return false;
      }

      return true;
    }
  }
}
</script>
